import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-01-17 09:56:50
 */
//获取表格数据，以及筛选
export function findCommodityClassPageList(parameter){
    return axios({
        url: '/goodsCategroy/page',
        method: 'post',
        params:parameter.page,
        data: parameter
    })
}
//二级分类列表
export function allPageList(){
    return axios({
        url: '/goodsCategroy/list',
        method: 'post'
    })
}
//商品分类新增
export function commodityClassAdd(parameter){
    return axios({
        url: '/goodsCategroy/add',
        method: 'post',
        data: parameter
    })
}
//商品分类编辑
export function commodityClassEdit(parameter){
    return axios({
        url: '/goodsCategroy/edit',
        method: 'post',
        data: parameter
    })
}
//商品分类删除/显示/隐藏
export function commodityClassDelete(parameter){
    return axios({
        url: '/goodsCategroy/delete',
        method: 'post',
        data: parameter
    })
}
//商品分类详情
export function commodityClassDetail(parameter){
    return axios({
        url: '/goodsCategroy/detail',
        method: 'post',
        params: parameter
    })
}

/**
 * 根据store token获取代理类目和分类
 * @param {*} parameter 
 * @returns 
 */
export function findCategoryListByStore(parameter){
    return axios({
        url: "/goodsCategroy/listByStore",
        method: 'post',
        params: parameter
    })
}